<template>
    <v-container>
        <v-row>
            <v-col lg="12"> 
            <v-container class="pa-1" fluid>
                <v-card class="px-3 pb-0">
                    <v-card-title class="pl-0 pt-2">
                        <v-icon>mdi-magnify</v-icon>
                        Filtro
                    </v-card-title>
                    <v-row >
                        <v-col
                            md="3"
                            lg="3"
                            sm="6"
                            cols="12"
                            class="text-center">
                            <v-menu
                                ref="menu"
                                v-model="menu"
                                :close-on-content-click="false"
                                :return-value.sync="desde"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="290px"
                                label="Desde"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    dense
                                    hide-details
                                    v-model="desde"
                                    label="Desde"
                                    prepend-inner-icon="mdi-calendar"
                                    v-bind="attrs"
                                    v-on="on"
                                    clearable
                                    outlined
                                ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="desde"
                                    type="month"
                                    no-title
                                    scrollable
                                    locale="cl-es"
                                    color="primary"
                                >
                                <v-btn  text color="primary" @click="menu = false">Cancelar</v-btn>
                                <v-btn  text color="primary" @click="$refs.menu.save(desde)">Aceptar</v-btn>
                                </v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col
                            md="3"
                            lg="3"
                            sm="6"
                            cols="12"
                            class="text-center ">
                        <v-menu
                                ref="menu2"
                                v-model="menu2"
                                :close-on-content-click="false"
                                :return-value.sync="hasta"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="290px"
                                label="Hasta"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    dense
                                    hide-details
                                    v-model="hasta"
                                    label="Hasta"
                                    prepend-inner-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    clearable
                                    outlined
                                ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="hasta"
                                    type="month"
                                    no-title
                                    scrollable
                                    locale="cl-es"
                                    color="primary"
                                    label="Hasta"
                                >
                                <v-btn  text color="primary" @click="menu2 = false">Cancelar</v-btn>
                                <v-btn  text color="primary" @click="$refs.menu2.save(hasta)">Aceptar</v-btn>
                                </v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col
                            md="3"
                            lg="3"
                            sm="6"
                            cols="12"
                            class="text-center">
                         <v-autocomplete
                            label="Estado"
                            dense
                            hide-details
                            v-model="estado"
                            :items="filter.estado"
                            color="primary"
                            item-text="estado"
                            item-value="valor"
                            placeholder="Estado"
                            clearable
                            outlined
                        ></v-autocomplete>
                        </v-col>
                        <v-col
                            md="3"
                            lg="3"
                            sm="6"
                            cols="12"
                            class="text-center ">
                            <v-container class="pa-0 ma-0" fluid>
                                <v-layout class="pa-0 ma-0">
                                    <v-col cols="12" md="10" lg="10" sm="10" class="pa-0 ma-0">
                                        <v-btn block color="primary" @click="filtrarDatos">
                                            Filtrar
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="12" md="2" lg="2" sm="2" class="pa-0 ma-0">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn
                                                icon
                                                color="green"
                                                 v-bind="attrs"
                                                v-on="on"
                                                @click="restablecer"
                                                >
                                                <v-icon>mdi-cached</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Restablecer Filtros</span>
                                        </v-tooltip>
                                    </v-col>
                                </v-layout>
                            </v-container>
                        </v-col>
                    </v-row>
                </v-card>
                </v-container>
                <v-container class="pa-1" fluid>
                    <v-row>
                        <v-col
                           md="12"
                            lg="12"
                            sm="12"
                            cols="12"
                            class="text-center"
                            >
                              <base-v-component
                                heading="Simple Tables"
                                link="components/simple-tables"
                                />
                                <base-material-card
                                icon="mdi-clipboard-check"
                                title="Cobros"
                                class="py-3"
                                color="primary"
                                :registros="total"
                               
                                >
                            <v-data-table  dense
                                no-data-text="No existe registros en tu cuenta"
                                :headers="headers"
                                :items="datos"
                                :options.sync="options"
                                :server-items-length="totalDesserts"
                                :loading="loading"
                                class="elevation-1"
                                :footer-props="{
                                    itemsPerPageOptions: [5, 10], // -> Add this example
                                    showFirstLastPage: true,
                                    disablePagination:loading,
                                    firstIcon: 'mdi-arrow-collapse-left',
                                    lastIcon: 'mdi-arrow-collapse-right',
                                    prevIcon: 'mdi-minus',
                                    nextIcon: 'mdi-plus',
                                    'items-per-page-text':'Registros por página'
                                }">
                                
                                <template v-slot:body="{ items }">
                                    <tbody>
                                    <tr v-for="item in items" :key="item.name">
                                        <td>{{ item.id }}</td>
                                        <td>{{ item.fecha_inicio_periodo }}</td>
                                        <td>{{ item.total_peticiones }}</td>
                                        <td>{{ item.total_cobro | currency}}</td>
                                        <td>{{ item.created }}</td>
                                        <td>{{ item.fecha_pagado }}</td>
                                        <td>{{ item.observaciones }}</td>
                                        <td>
                                           <!--v-btn v-model="item.accion" small color="primary" @click="verdatos(item.id)">ver</v-btn-->
                                           <v-tooltip bottom>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon color="primary mx-1" @click="verdatos(item.id)"  v-bind="attrs" v-on="on">
                                                            mdi-monitor-eye 
                                                        </v-icon>
                                                    </template>
                                                <span>Ver Cobro</span>
                                                </v-tooltip>
                                        </td>
                                    </tr>
                                    </tbody>
                                </template>
                               
                                </v-data-table>
                             </base-material-card>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import API from '@/api'
import {EventBus} from '@/main.js'
var moment = require('moment')
var _ = require('underscore')
export default {
    name: 'MisCobros',
    title: 'Mis cobros - Onestock',
    data: ()=>({
        total:0,
        menu: false,
        menu2:false,
        reactive:true,
        readonly:true,
        loading:false,
        totalDesserts: 0,
        desserts: [],
        options: {},
        headers: [
            {
                text: 'ID',
                align: 'Center',
                sortable: true,
                value:'id'
            },
            { 
                text: 'Periodo', 
                 align: 'Center',
                value: 'fecha_inicio_periodo', 
                sortable: false,
            },
            { 
                text: 'Total Peticiones', 
                 align: 'Center',
                value: 'total_peticiones',
                 sortable: false,
            },
            { 
                text: 'Monto Total', 
                 align: 'Center',
                value: 'total_cobro',
                 sortable: false, 
            },
            { 
                text: 'Fecha Creación', 
                 align: 'Center',
                value: 'created',
                 sortable: false,
            },
            { 
                text: 'Fecha Pago', 
                 align: 'Center',
                value: 'fecha_pagado',
                 sortable: false,
            },
            { 
                text: 'Comentario', 
                 align: 'Center',
                value: 'observaciones', 
                 sortable: false,
            },
            {
                text:'Acciones',
                 align: 'Center',
                value:'accion',
                 sortable: false,
            }
        ],
        datos:[],
        estado:'',
        desde: '',
        hasta:'',
        filter:{
            estado:[
                {
                    id:1,
                    estado:'Pagado',
                    valor:1
                },
                {
                    id:2,
                    estado: 'No pagado',
                    valor:0
                }

            ],
            desde:[],
            hasta:[]
        }

    }),
      beforeMount(){
        this.filtrarDatos()
    },methods:{
        restablecer(){
            this.$data.desde = ''
            this.$data.hasta = ''
            this.$data.estado = ''
            this.filtrarDatos()
        },
        verdatos(id){
            this.$router.push('/clientes/cobros/ver-cobros/'+id)
        },
        filtrarDatos(){
            this.$data.loading=true
            EventBus.$emit('cargando',true)
            let desde
            this.$data.desde != undefined
            ?  desde = this.$data.desde 
            : desde = ''
            
            let hasta
            this.$data.hasta != undefined
            ? hasta = this.$data.hasta
            : hasta = ''
        
            let estado
            this.$data.estado != undefined
            ? estado = this.$data.estado
            : estado = ''

            const { sortBy, sortDesc, page, itemsPerPage } = this.options
            // console.log( sortBy, sortDesc, page, itemsPerPage)
            let direction = 'asc'
            sortDesc[0] != true ? direction = 'asc' : direction = "desc"
            
                
                let order = 'id'
            
                if(sortBy[0]){
                    order = sortBy
                }

            API.cobrosCliente(this.$store.state.token,this.$store.state.user.id,direction,order,desde,hasta,estado,page,itemsPerPage)
            .then((res)=>{
                this.$data.total = res.data.respuesta.total
                EventBus.$emit('cargando',false)
                this.$data.loading=false
                this.$data.datos= res.data.respuesta.data
                this.$data.datos.forEach((el)=>{
                    el.fecha_inicio_periodo = moment(el.fecha_inicio_periodo).format('DD-MM-YYYY')
                    el.created = moment(el.created).format('DD-MM-YYYY')
                    el.fecha_pagado != null ? el.fecha_pagado = moment(el.fecha_pagado).format('DD-MM-YYYY') : '-'
                })
                let items = this.$data.datos
                const total = items.length
                this.$data.totalDesserts = res.data.respuesta.last_page * itemsPerPage
            })
            .catch((err)=>{
                EventBus.$emit('cargando',false)
                this.$data.loading=false
                console.log(err)
            })
        },
        filtrarDatos2(){
            this.$data.loading=true
            EventBus.$emit('cargando',true)
            let desde
            this.$data.desde != undefined
            ?  desde = this.$data.desde 
            : desde = ''
            
            let hasta
            this.$data.hasta != undefined
            ? hasta = this.$data.hasta
            : hasta = ''
        
            let estado
            this.$data.estado != undefined
            ? estado = this.$data.estado
            : estado = ''

            const { sortBy, sortDesc, page, itemsPerPage } = this.options
            // console.log( sortBy, sortDesc, page, itemsPerPage)
            let direction = 'asc'
            sortDesc[0] != true ? direction = 'asc' : direction = "desc"
            
                
                let order = 'id'
            
                if(sortBy[0]){
                    order = sortBy
                }
            this.options.page = 1

            API.cobrosCliente(this.$store.state.token,this.$store.state.user.id,direction,order,desde,hasta,estado,1,itemsPerPage)
            .then((res)=>{
                this.$data.total = res.data.respuesta.total
                EventBus.$emit('cargando',false)
                this.$data.loading=false
                this.$data.datos= res.data.respuesta.data
                this.$data.datos.forEach((el)=>{
                    el.fecha_inicio_periodo = moment(el.fecha_inicio_periodo).format('DD-MM-YYYY')
                    el.created = moment(el.created).format('DD-MM-YYYY')
                    el.fecha_pagado != null ? el.fecha_pagado = moment(el.fecha_pagado).format('DD-MM-YYYY') : '-'
                })
                let items = this.$data.datos
                const total = items.length
                this.$data.totalDesserts = res.data.respuesta.last_page * itemsPerPage
            })
            .catch((err)=>{
                EventBus.$emit('cargando',false)
                this.$data.loading=false
                console.log(err)
            })
        }
    },
     watch:{
        options: {
        handler () {
          this.filtrarDatos()
        },
        deep: true,
        },
        desde(v){
            if(v == null){
                this.$data.desde = ''
                this.filtrarDatos2();
            }
        },
        hasta(v){
            if(v == null){
                this.$data.hasta = ''
                this.filtrarDatos2();
            }
        },
        estado(v){
          if(v == null){
              this.$data.estado = ''
               this.filtrarDatos2();
          }
        },
    }
}
</script>  

<style lang="css">
.v-btn{
    padding: 0px !important;
}
</style>